import { ID_KRPANO_CONTAINER } from '@containers/Home/constants';
import styled, { createGlobalStyle, css } from 'styled-components';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  :root {
    --color-theme: #D1005C;
    --color-red: #E54D42;
    --color-green: #2FD0AC;
    --color-blue: #007AFF;
    --color-yellow: #F0C330;
    --color-red: red;
    --color-smoke: #969FA7;
    --color-dark: #212121;
    --color-dark-2: #393839;
    --color-light: #E3E8EE;


    --color-bg: #151127;
    --color-text: #fff;
    --color-primary: #dab952;
    --color-secondary: #31285b;

    --transition-regular: all 0.15s ease-in-out;
    --transition-smooth: all 0.5s cubic-bezier(0.04, 1, 0.6, 0.97);
    --transition-smooth-2: all 1.5s cubic-bezier(0.04, 1, 0.6, 0.97);
    --fontSize-h1: 32px;
    --fontSize-h2: 28px;
    --fontSize-h3: 24px;
    --fontSize-h4: 20px;
    --fontSize-h5: 16px;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    /* font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    font-size: 14px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--color-text);
  }

  #__next {
    width: 100%;
    height: calc(100%);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .cursorPointer {
    cursor: pointer !important;
  }

  body {
    > #${ID_KRPANO_CONTAINER} {
      position: absolute !important;
      z-index: 10000 !important;
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
      border-right-color: unset;
    }

    .ctn__html {
      * {
        color: inherit;
      }
      p {
        margin-bottom: .75rem;
      }
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }
      figure {
        margin: 0 auto .75rem!important;
        padding: 0!important;
        img {

        }
        figcaption {
          background: rgba(0,0,0,0.1);
          padding: .25rem 0;
          text-align: center;
          > * {
            margin: 0;
          }
        }
        iframe {
          min-height: 240px;
        }
      }
      table {
        border: 1px solid #ddd;
        margin-bottom: .75rem;
      }
      tr {

      }
      td {
        border: 1px solid #ddd;
        padding: .25rem;
      }
    }

    .ant-popover {
      padding: 0;
      &-message,
      &-buttons {
        padding: 10px 12px;
      }
      &-message {
        padding-bottom: 0;
        > .anticon {
          top: 14px;
        }
      }
      &-placement-top > .ant-popover-content > .ant-popover-arrow {
        bottom: -4.8px;
      }
      &-placement-bottom > .ant-popover-content > .ant-popover-arrow {
        top: 8px;
      }
      &-arrow {
        display: none;
        /* border-color: #000!important; */
      }
      &-inner {
        border-radius: 4px;
        background: transparent;
        box-shadow: none;
        &-content {
          padding: .25rem .25rem;
        }
      }
      &.popoverPreviewHotspot {
        .ant-popover-inner {
          background: rgb(0 0 0 / 0.75);
          iframe {
            border: none!important;
            outline: none!important;
          }
        }
      }
    }


    .popoverControlbar {
      .ant-popover-inner-content {
        padding: .5rem .5rem;
        background: #212121;
        border-radius: 8px;
      }
    }


    .popoverSceneList {
      .ant-popover-inner {
        background: transparent;
        box-shadow: none;
        &-content {
          padding: 0;
        }
      }
      .dropdownList {
        .--listing {
        }
        .--menuSceneTitle {
          position: relative;
          cursor: pointer;
          /* text-transform: uppercase; */
          background: none;
          border-radius: 0;
          color: var(--color-text);
          border: 1px solid var(--color-primary);
          border-bottom: none;
          padding: 0.5rem 1rem;
          width: 100%;
          background: var(--color-primary);
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          flex: 0 0 auto;
          transform: translateY(0.5rem);
          &.active {
            background: #fff;
            color: var(--color-primary);
            border-color: transparent;
            box-shadow: 0 12px 24px rgb(0 0 0 / 0.15);
          }
          &:last-child {
            border: 1px solid var(--color-primary);
          }
        }
      }
    }

    .ant-badge-count {
      z-index: 3;
    }

    .fancybox__container {
      z-index: 101 !important;
    }
    .fancybox__content{
      height: 90vh !important;
      padding: 2px;
      border-radius: 8px;
    }
    .ant-modal {
      padding-bottom: 0;
      border: none !important;
      /* min-height: 200px; */
      display: inline-flex !important;
      /* overflow: hidden; */
      &.dialogGuide,&.dialogNavigate,&.dialogSearch{
        .ant-modal-body{
          box-shadow: none;
          overflow: hidden;
          border-radius: 24px;
          border: 1px solid #00A1F9;
        }
      }
      &.dialogNavigate{
        .close{
          display: none;
        }
      }
      &-close {
        display: none;
      }
      &-body {
        /* max-height: 85vh; */
        padding: 0;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        margin: 0 auto;
        border-radius: 12px;
        overflow: hidden;

        border: 4px solid var(--color-bg);
        box-shadow: 0 0 0 4px var(--color-primary);
        border-radius: 4px;
      }
      &-mask {
        background-color: rgba(0, 0, 0, 0.75);
      }
      &-content {
        width: 100%;
        /* height: 100%; */
        background-color: transparent;
      }
    }

    .ant-btn > i, .ant-btn > span {
      display: inline-flex !important;
    }

    img {
      width: 100%;
      height: auto;
    }

    iframe {
      /* width: 100% !important; */
      height: auto;
    }
    p {
      margin: unset;
    }

    .se-component {
      display: inline-block;
    }

    .se-image-container {
      display: block;
    }
  }

  .tree-scene__collapse {
    padding-bottom: .5rem;
    border-bottom: 1px solid rgb(255 255 255 / .15);
    .ant-collapse {
      &-content {
      }
      &-content-box {
        padding-left: 0!important;
        padding-right: 0!important;
      }
    }
  }


  .inline-flex {
    display: inline-flex !important;
    flex: 0 0 auto;
  }

  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hide-scrollbar {
    &::-webkit-scrollbar {
      /* This is the magic bit for WebKit */
      display: none;
    }
  }

  .el-hide {
    display: none !important;
  }

  .hotspotTitle {
    em {
      color: #fff;
      display: block;
      text-align: center;
      margin: 0 auto;
      font-style: initial;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
    }
  }

  .slideModal {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
    .ant-modal-content, .ant-modal-body{
      height: 100%;
      background: rgba(0, 0, 0, .25);
      /* backdrop-filter: blur(4px); */
    }
  }

  .flickity {
    &-button {
      svg {
        width: 40%!important;
        top: 50%!important;
        left: 50%!important;
        transform: translate(-50%, -50%)!important;
      }
    }
  }


  .shopify-buy-frame.is-sticky {
    right: 0 !important;
    top: 20% !important;
    iframe {
      width: 46px !important;
    }
  }


  /*======= Map Styling ============*/
  .gmnoprint {
    display:none;
  }

  .ant-select {
    &-dropdown {
      border-radius: 4px!important;
      min-width: 140px!important;
      background: var(--color-dark)!important;
      border: 1px solid rgb(255 255 255 / .25)!important;
      .ant-empty-description {
        color: #fff;
      }
    }
    &-item {
      margin: 0!important;
      padding: .5rem!important;
      /* border-radius: 6px!important; */
      color: #fff!important;
      &-option-active {
        background-color: var(--color-dark-2)!important;
      }
      &-option-selected {
        background-color: var(--color-theme)!important;
      }
    }
    .ant-select-clear {
      color: var(--color-smoke);
      right: 26px;
      margin-top: -8px;
    }
    .ant-select-arrow {
      color: var(--color-smoke);
    }
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }



  .btnSecondary {
    background: #fff;
    border-radius: 25px;
    padding: 8px 54px;
    color: var(--color-primary);
  }

  .btnTxtLink {
    text-decoration: underline;
  }
`;

export const Flex = css`
  display: flex;
`;

export const FlexCol = css`
  display: flex;
  flex-direction: column;
`;

export const FlexRowCenter = css`
  ${Flex};
  justify-content: center;
  align-items: center;
`;

export const FlexRowStart = css`
  ${Flex};
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowEnd = css`
  ${Flex};
  justify-content: flex-end;
  align-items: center;
`;

export const FlexRowBetween = css`
  ${Flex}
  justify-content: space-between;
  align-items: center;
`;

export const FlexColCenter = css`
  ${FlexCol};
  ${FlexRowCenter}
`;

export const FlexColStart = css`
  ${FlexCol};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FlexColEnd = css`
  ${FlexCol};
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FlexColBetween = css`
  ${FlexCol};
  justify-content: space-between;
  align-items: baseline;
`;

export const BackgroundCover = css<{ bgUrl: string | null | undefined }>`
  background-image: url(${props => props.bgUrl && props.bgUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackgroundContain = css`
  ${BackgroundCover};
  background-size: contain;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${FlexRowCenter};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;
`;
